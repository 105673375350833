.wrap {
    text-align: left;
    padding-left: 0.5rem;
    display: flex;
}

.wrap .name {
    width: 30%;
    text-align: left;
}


.wrap .value {
    text-align: left;
    width: 60%;
}

.wrap .value .p-inputtext {
    padding: 0.25rem;
    border-radius: 4px;
}

.wrap .value .p-inputtextarea {
    width: 100%;
}

.ticket-form-container ul {
    list-style: none;
    margin-block-start: 0;
    padding-inline-start: 0;
    margin-top: 1rem;
}

.ticket-form-container ul li {
    margin-bottom: 0.5rem;
}

.ticket-form-container .p-card .p-card-header {
    text-align: left;
    padding: 0.75rem 0.75rem;
    font-weight: bold;
    border-bottom: solid 2px rgba(244, 244, 244, 1);
    ;
}

.ticket-form-container {
    width: 100vw;
    height: 100vh;
    padding: 2rem 0;
    background-color: rgba(244, 244, 244, 1);
}

.ticket-form-container .p-card {
    max-width: 800px;
    margin: auto;
}

.ticket-form-container .p-card-footer {
    border-top: solid 2px rgba(244, 244, 244, 1);
    display: flex;
    justify-content: space-between;
    text-align: left;
    list-style: none;
    font-size: small;
    color: orange;
    padding: 0.75rem 0 0 0;

}

.ticket-form-container .p-card-footer button {
    padding: 0.5rem 1.25rem;

}

.priority-option,
.selected-priority {
    display: flex;
    flex-direction: row;
}

.priority-option img,
.selected-priority img {
    margin-top: 0.2rem;
    height: 16px;
    width: 16px;
    margin-right: 0.5rem;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0.75rem 0.25rem;
}

.empty-file-holder {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.file-item-template-holder,
.file-item-template {
    display: flex;
    align-items: center;
    text-align: center;
}

.file-item-template {
    width: 65%;
}
.file-item-template-holder{
    padding: 0 !important;
    justify-content: space-between;
}
.file-item-template-holder i{
    font-size: 2rem;
    color: cadetblue;
}

.file-name {
    display: flex;
    text-align: left;
    flex-direction: column;
    word-break: break-all;
}

.p-message-summary {
    max-width: 55%;
    word-break: break-all;
    margin-right: 0.5rem;
}

.upload-header-container {
    background-color: 'transparent';
    display: 'flex';
    align-items: 'center';
    flex-direction: column;
    border: 1px solid #dee2e6;
    color: #343a40;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}
.upload-option-holder{
    padding: 0.5rem;
    flex-direction: column;
}

.upload-header-container button{
    margin-left: 0.25rem;
}

.no-progress-bar{
    display: none;
}

.upload-header-container .p-progressbar{
    height: 1.25rem;
    border-radius: 0;
}
.custom-upload{
    display: none;
}

.ticket-holder .p-card-content {
    padding: 0;
    text-align: left;
}

.mandatory-msg{
    font-size: small;
}
.astreisk {
    color: red;
}

.progress-container{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    top:0;
    background-color: rgba(0,0,0,0.1);
}

.progress-container .p-progress-spinner{
    transform: translateY(35vh);
}