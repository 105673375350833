.login-form-container{
    width:100vw;
    transform: translateY(30vh);
}

.login-card{
    max-width: 25rem;
    margin: auto;
}

.login-field {
    margin-bottom: 0.25rem;
}

span.clear{
    clear: both;
    display: block;
}
.button-field Button{
    width: auto !important;
    float: right;
}

.login-card .p-card-content {
    padding: 1.25rem 0 0 0;

}