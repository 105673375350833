.dashboard-container .p-toolbar{
    padding: 0.25rem 1.25rem;
}
.dashboard-container .p-toolbar .p-toolbar-group-right .p-button{
    padding: 0.5rem 1.25rem;
}

.tic-card {
    margin-top: 0.5rem;
    border-radius: 0;
    border-bottom: solid 1px rgb(200, 200, 200);
}
.tic-card:active{
    background-color: lightgreen;
}
.tic-card-header {
    text-align: left;
}

.tic-card-header span {
    padding: 0.25rem 0.1rem 0.1rem 0.5rem;
}

.ticket-id {
    font-weight: bold;
}

.tic-card .p-card-footer {
    padding: 0;
    text-align: right;
}

.tic-card .p-card-body {
    padding: 0.2rem 0.2rem;
}

.tic-card-footer .p-chip .p-chip-text {
    margin: 0;
}

.tic-card-footer .p-chip .p-chip-icon {
    margin-right: 0.25rem;
    color: blue;
}

.tic-card-footer .p-chip {
    padding: 0;
    background-color: #fff;
}

.tic-card .p-card-content{
    padding: 0.5rem 0;
    text-align: left;
}
.cu-avatar{
   background-color:#9c27b0;
   color: #fff;
}

.priority-blocker{
    border-left: solid 5px rgba(184, 18, 66 ,1);
}

.priority-critical{
    border-left: solid 5px rgba(184, 18, 66 ,0.8);
}

.priority-major{
    border-left: solid 5px rgba(241, 132, 32,1);
}
.priority-medium{
    border-left: solid 5px rgba(244, 205, 0,1);
}
.priority-low{
    border-left: solid 5px rgba(244, 205, 0,0.8);
}

.priority-minor{
    border-left: solid 5px rgba(104, 172, 50,1);
}