.dashboard-container {
  display: flex;
  flex-direction: column;
}

.tickets-container {
  width: 70%;

}

.ticket-details-container {
  width: 30%;
  height: calc(100vh - 5rem);
  padding: 1.25rem;
  background: #ffffff;
  color: #495057;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 6px;
  padding-bottom: 0.25rem;
  overflow-y: auto;
}

.ticket-details-container .p-card .p-card-header {
  text-align: left;
  padding: 0.25rem 0.5rem;
  font-weight: bold;
}

.ticket-details-container .p-card .p-card-body {
  padding: 0 1rem;
}

.ticket-details-container .p-card .p-card-body .p-card-content {
  padding: 0;
  text-align: left;
}

.tic-details-header,
.tic-details,
.tic-people,
.tic-dates,
.tic-description,
.tic-attachment,
.tic-comments {
  border-radius: 0;
  box-shadow: none;
}

.tic-list-container {
  background-color: rgba(244, 244, 244, 1);
  height: calc(100vh - 12rem);
  overflow: auto;
  padding: 0 0.3rem;
}

.p-splitter-panel {
  padding: 0 2px;
}

.tic-list-container::-webkit-scrollbar {
  display: none;
}

.dashboard-body {
  display: flex;
  flex-direction: row;
}

.wrap {
  padding-left: 0.5rem;
  display: flex;
}

.wrap .name {
  width: 40%;
  text-align: left;
}

.wrap .value {
  display: inherit;
}



.ticket-details-container ul {
  list-style: none;
  margin-block-start: 0;
  padding: 0;
}

.description {
  width: 100%;
}

.p-splitter-gutter {
  background-color: #fff !important;
}

.tic-comments .p-card-body .chat-container {
  height: calc(100vh - 45rem);
  border-radius: 5px;
  border: solid 1px #ced4da;
  overflow-y: auto;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tic-comments .p-card-body .p-card-footer {
  padding: 0;
}

.tic-comments .p-card-body .p-card-footer .chat-inp-container,
.tic-comments .p-card-body .p-card-footer .chat-inp-container .chat-input {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}


.tic-comments .p-card-body .p-card-footer .p-inputtext {

  border: none;
  width: 100%;
}

.attachment-button {
  border-radius: 0;
}

.post-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}

.chat-input {
  width: calc(100% - 3rem);
  padding: 0.25rem;
}

.chat-inp-container {
  border: solid 1px #ced4da;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.chat-input .p-inputtext:enabled:focus {
  box-shadow: none;
}

.file-content {
  display: none !important;
}



.selected-file {
  color: black;
  padding: 0.2rem 0.5rem;
  background: lightgray;
  margin: 0.3rem;
  border-radius: 5px;
  width: 119px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: fit-content;
}

.custom-progress-bar {
  position: relative;
  overflow: hidden;
  height: 10px;
  width: 98%;
}

.file-name {
  display: inline-block;
  width: 106px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: center;
}

.remove-btn {
  width: 20px !important;
  position: absolute;
  height: 20px !important;
  left: 105px;
  top: -4px;
}

.selected-files {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}


.selected-files::-webkit-scrollbar {
  height: 3px;
}

.selected-files::-webkit-scrollbar-track {
  background-color: pink;
  border-radius: 5px;
}

.selected-files::-webkit-scrollbar-thumb {
  background-color: green;
}

.chat-container::-webkit-scrollbar {
  width: 3px;
}

.chat-container::-webkit-scrollbar-track {
  background-color: pink;
  border-radius: 5px;
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: green;
}

.chat-container {
  padding: 0.25rem;
  min-height: 6rem
}

.comment {
  width: 60%;
  margin-bottom: 0.25rem;
  border: solid 1px rgb(0 0 0 /12%);
  border-radius: 10px;
}

.comment .p-card-header {
  display: flex;
  justify-content: space-between;
}

.float-left {
  float: left;
  border-bottom-left-radius: 0;
  background-color: #d8d8d8;
  box-shadow: rgba(216, 216, 216, 0.16) 0px 3px 6px, rgba(216, 216, 216, 0.23) 0px 3px 6px;
}

.float-right {
  float: right;
  border-bottom-right-radius: 0;
  background-color: #2fb396;
  color: white;
  box-shadow: rgba(47, 179, 150, 0.16) 0px 3px 6px, rgba(47, 179, 150, 0.23) 0px 3px 6px;
}

.action-icon,
.edit-action-icon {
  margin-left: 4px;
}
.edit-action-icon{
  margin-top: 6px;
}

.file-link{
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}